<template>
  <div>
    <Navbar mode="solid" class="z-40 relative w-full top-0" />
    <main class="main">
      <div class="wrapper">
        <div class="text-wrapper">
          <div class="flex justify-center text-center flex-col" v-if="loading">
            <i class="bx bx-loader-alt bx-spin text-5xl text-teal-base"></i>
            <h1 class="page-title">{{ $t("business_request.button_loading") }}</h1>
          </div>
          <div id="trip-request" v-if="request_route && !loading">
            <div class="icon-container">
              <i class="bx bxs-like icon"></i>
            </div>
            <h1 class="page-title">{{ $t("thank_you.request.title") }}</h1>
            <p class="body-text">
              {{ $t("thank_you.request.text") }}
            </p>
            <ButtonLink :text="`${$t('thank_you.request.button')}`" route="/account/trips" class="btn-style" />
          </div>
          <div id="trip-request-success" v-if="request_success_route && !loading">
            <div class="icon-container">
              <i class="bx bxs-like icon"></i>
            </div>
            <h1 class="page-title">{{ $t("thank_you.booking.title") }}</h1>
            <p class="body-text">
              {{ $t("thank_you.booking.text_1") }}
              <br /><br />{{ $t("thank_you.booking.text_2") }}
            </p>
          </div>
          <div id="trip-booking" v-if="booking_route && !loading">
            <div class="icon-container">
              <i class="bx bxs-like icon"></i>
            </div>
            <h1 class="page-title">{{ $t("thank_you.booking.title") }}</h1>
            <p class="body-text">
              {{ $t("thank_you.booking.text_1") }}
              <br /><br />{{ $t("thank_you.booking.text_2") }}
            </p>
          </div>
          <div id="business-request" v-if="business_route">
            <div class="icon-container">
              <i class="bx bxs-like icon"></i>
            </div>
            <h1 class="page-title">
              {{ $t("thank_you.business.title") }}
            </h1>
            <p class="body-text">
              {{ $t("thank_you.business.text_1") }}
              <br /><br />
              {{ $t("thank_you.business.text_2") }}
            </p>
          </div>
          <div v-if="error_case && !loading">
            <EmptyState icon-color="red" icon-class="bx bxs-error" :title="$t('empty_state.something_went_wrong.title')"
              :text="$t('empty_state.something_went_wrong.text')" :use-link="true"
              :btn-text="$t('cta.go_to_homepage')" />
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import ButtonLink from "@/components/buttons/ButtonLink";
import { mapGetters, mapActions } from "vuex";
import EmptyState from "@/components/EmptyState";
import api from "../../api";
export default {
  name:"ThankYou",
  components:{ ButtonLink, EmptyState },
  data:() => {
    return {
      requestStore:() => this.request, //Only use is to prevent error in console
      loading:true,
    };
  },
  methods:{
    ...mapActions({
      resetRequestState:"request/resetRequestState",
    })
  },
  computed:{
    ...mapGetters("request", ["tripDetails"]),
    ...mapGetters({
      isThankYouPage:"request/isThankYouPage",
      tripRequestCheckout:"request/tripRequestCheckout"
    }),
    business_route() {
      if (this.$route.path.includes("/business/thank-you")) return true;
      return false;
    },
    booking_route() {
      if (this.$route.path.includes("/payment/thank-you")) return true;
      return false;
    },
    request_route() {
      if (
        this.$route.path.includes("/request/thank-you") &&
        ("country" in this.$route.query) &&
        this.isThankYouPage
      ) return true;

      return false;
    },
    request_success_route() {
      if (
        this.$route.path.includes("/request/success") &&
        !("country" in this.$route.query) &&
        this.isThankYouPage &&
        this.tripRequestCheckout.checkout_session_id !== undefined &&
        this.tripRequestCheckout.transaction_id !== undefined &&
        this.tripRequestCheckout.offer_id !== undefined
      ) return true;

      return false;
    },
    error_case() {
      if (!this.business_route && !this.booking_route && !this.request_route && !this.request_success_route) {
        return true;
      }
      return false;
    }
  },
  destroyed() {
    this.resetRequestState();
  },
  async mounted() {
    console.log('Checkout Session ID:', this.tripRequestCheckout.checkout_session_id);
    console.log('Transaction ID:', this.tripRequestCheckout.transaction_id);
    console.log('Offer ID:', this.tripRequestCheckout.offer_id);

    try {
      if (this.request_success_route && !("country" in this.$route.query)) {
        await api.confirmPayment(this.tripRequestCheckout);
      }
    } catch (error) {
      console.error('Payment confirmation error:', error);
    } finally {
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.main {
  @apply px-4 pt-72 mb-40 md:px-12 h-auto;
}

.btn-style {
  @apply relative w-full mb-4 transform -translate-x-1/2 xs:w-80 left-1/2;
}

.wrapper {
  @apply relative w-full transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2;
}

.text-wrapper {
  @apply relative w-full text-left transform -translate-x-1/2 max-w-screen-sm left-1/2;
}

.icon-container {
  @apply relative flex items-center justify-center w-32 h-32 p-8 text-3xl transform -translate-x-1/2 rounded-full left-1/2 mb-8;
  @apply bg-teal-lighter;
}

.icon {
  @apply text-5xl text-teal-base;
}

.page-title {
  @apply mb-4 font-sans text-2xl font-bold text-black-base xs:text-center;
}

.body-text {
  @apply mb-4 font-sans text-black-base xs:text-center;
}
</style>
